import dynamic from 'next/dynamic'
import Image from 'next/image'

import { BestMoneyLogoWhite, ForbesLogoWhite, UsaTodayLogo } from '@/icons'
import logoWhite from 'src/img/ethos-logo-white.svg'

import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import { useDirectToAppUx } from '@/hooks/features/useDirectToAppUx'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

const IconV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Icon),
  { ssr: false }
)

const partnerLogoMap = {
  forbes: (
    <div className="w-[111px] lg:w-[121px]">
      <ForbesLogoWhite />
    </div>
  ),
  bestMoney: (
    <div className="mt-1 w-[92px] lg:w-[107px]">
      <BestMoneyLogoWhite />
    </div>
  ),
  usaToday: (
    <div className="w-[65px] lg:w-[76px]">
      <UsaTodayLogo />
    </div>
  ),
}

const getPartnerLogo = (
  isForbesHeaderEnabled: boolean,
  isBestMoneyHeaderEnabled: boolean,
  isForbesUsaTodayHeaderEnabled: boolean
) => {
  if (isForbesHeaderEnabled) {
    return partnerLogoMap.forbes
  }
  if (isBestMoneyHeaderEnabled) {
    return partnerLogoMap.bestMoney
  }
  if (isForbesUsaTodayHeaderEnabled) {
    return partnerLogoMap.usaToday
  }
  return null
}

export const Banner = () => {
  const { isMobile } = useDetectScreenSize()
  const isForbesHeaderEnabled = useForbesHeaderNavbar()
  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar()
  const { isEnabled: isBestMoneyHeaderEnabled } = useBestMoneyHeader()
  const { isVariant3 } = useDirectToAppUx()

  const partnerLogo = getPartnerLogo(
    isForbesHeaderEnabled,
    isBestMoneyHeaderEnabled,
    isForbesUsaTodayHeaderEnabled
  )

  const shouldDisplayForbesLogo =
    !isVariant3 &&
    !isForbesHeaderEnabled &&
    !isBestMoneyHeaderEnabled &&
    !isForbesUsaTodayHeaderEnabled

  return (
    <div className="flex h-[60px] items-center justify-between gap-2 border-b-gray-2 bg-cypress-125 px-5 py-4 md:justify-start md:gap-8 md:border-b-[1px] md:border-solid md:px-20 lg:h-20">
      <div className="flex items-center justify-center">
        <Image
          src={logoWhite}
          alt="Ethos"
          priority
          width={isMobile ? 65 : 75}
          height={isMobile ? 16 : 14}
        />
        {partnerLogo && (
          <div className="flex items-center">
            <IconV2
              name="add"
              sx={{ color: 'white', margin: '0 8px' }}
              baseClassName="material-icons-outlined"
            />
            {partnerLogo}
          </div>
        )}
      </div>
      {shouldDisplayForbesLogo && (
        <div className="relative h-8 w-[223px]">
          <Image
            priority
            src="https://res.cloudinary.com/getethos/image/upload/v1728275603/banner-left-group_rpihm7.svg"
            fill
            alt="Ethos"
          />
        </div>
      )}
    </div>
  )
}
